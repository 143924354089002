.p-button.openFormButton{
  width: 50px;
  text-align: center;
}

.p-button.submitFormButton{
  width: 50px;
  height: 35px;
  text-align: center;
  margin-right: 0.5rem;
  margin-top: 6px;
}

.formBox{
  margin-top: 10px;
  width: 100%;
}

.p-checkbox-label{
  margin-left: 3px;
}

.formBox form{
  align-items: flex-start;
  position: relative;
  width: 100%;
}

.p-col.checkboxCol{
  display: flex;
  align-items: center;
  padding-top: 15px;
}
