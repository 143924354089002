/* index.scss */

/* General reset for margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Variables for colors */
$green: #679e37;
$white: #ffffff;
$light-gray: #e0e0e0;
$dark-gray: #d0d0d0;
$blue: #3979f0;
$darken-factor: 25%;

/* Common classes */
.addCPVToList {
  background: $green !important;
  border-radius: 50%;

  &.disabled {
    background: $dark-gray !important;
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.p-field > label {
  width: 100%;
}

#campaignVersionId {
  max-width: 450px;
}

.p-fieldset .p-fieldset-legend {
  margin-left: 10px;
}
.p-field-radiobutton{
  margin: 8px;
}
/* Dashboard container */
.dashboard {
  padding: 20px;
  background: $white;
  border-radius: 10px;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

/* Form section */
.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Flex containers */
.row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.column-50,
.column-20,
.column-25,
.column-80 {
  flex: 1;
}

.column-50 {
  max-width: 50%;
}

.column-20 {
  max-width: 20%;
}
.column-25 { 
  max-width: 25%;
}
.column-80 {
  max-width: 80%;
}

/* Form fields */
.form-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.form-field label {
  font-size: 1.2rem;
}


.input-text,
.input-textarea {
  width: 100%;
}

.input-text {
  height: 40px;
}

.input-textarea {
  height: 40px;
  resize: vertical;
}

/* Form groups */
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &-20 {
    flex: 1;
    max-width: 20%;
  }

  &-80 {
    flex: 1;
    max-width: 80%;
  }
}

/* Campaign version table */
.campaignVersionTable {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.tableHeader,
.tableRow {
  display: flex;
}

.tableHeader {
  background-color: $light-gray;
}

.headerCell,
.tableCell {
  flex: 1;
  padding: 10px;
  text-align: left;

  &:first-child {
    text-align: left;
  }
}

.headerCell {
  font-weight: bold;
  border-bottom: 2px solid $dark-gray;
}

.tableCell {
  border-bottom: 1px solid $dark-gray;
}

/* Form actions */
.form-actions {
  display: flex;
  gap: 20px;
  margin: 30px auto 0;
}

.type,
.status {
  border: 1px solid $light-gray;
  background: $white;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 11px;
  margin-top: 16px;

  .topLabel {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    position: absolute;
    margin-top: -20px;
    background-color: $white;
    padding: 0 10px;
  }

  // .radioContainer {
  //   padding: 10px;
  // }
}

.status-text {
  font-size: 1rem;
  color: $white;
  padding: 0.5em;
  border-radius: 50px;
  width: 100px;
  text-align: center;
  margin: 30px auto;
}

/* Styles for devices below 992px */
@media screen and (max-width: 991px) {
  .dashboard {
    padding: 10px;
  }

  .row {
    flex-direction: column;
    gap: 10px; 
  }

  .column-50,
  .column-20,
  .column-25,
  .column-80 {
    max-width: 100%;
  }

  .form-field {
    gap: 5px;
  }

  .form-actions {
    gap: 10px;
    margin: 20px auto 0;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
}